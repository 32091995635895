function EmotionalConsulting() {
  return (
    <section id="emotional-consulting">
      <h2>Consultoria de educação emocional</h2>
      <div className="emotional-consulting-container">
        <p>
          Atendimento voltado para pessoas que querem integrar suas
          inteligências a partir de um dos pilares do método Estudar com Emoção,
          a saber:
        </p>
        <ol>
          <li>Autorreconhecimento de si (autoconhecimento);</li>
          <li>Emoções - pronomes possessivos? (gestão das emoções);</li>
          <li>Viver com consciência (automotivação e organização), e</li>
          <li>Comunicar a partir do coração (comunicação consciente).</li>
        </ol>
      </div>
    </section>
  );
}

export default EmotionalConsulting;
