import { useState } from "react";
import firula from "../assets/Vector.svg";
import fotoHero from "../assets/fotoHero.webp";
import ModalForm from "../components/_modalForm";

function HeroHome() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="hero-home">
      <div className="hero-home-content">
        <img
          src={firula}
          alt="pictograma"
        />
        <div className="hero-home-copy">
          <h1>Carla Mello</h1>
          <h2 className="hero-subtitle">Serviços Educacionais</h2>
          <p className="hero-copy">
            Estudar é para a vida toda, para todas as vidas.
          </p>
        </div>
        <button
          className="contact-btn"
          onClick={openModal}
        >
          Entre em contato
        </button>
      </div>
      <div className="photo-container">
        <img
          src={fotoHero}
          alt="foto Carla Mello"
          className="photo-hero"
          loading="lazy"
        />
      </div>
      <ModalForm
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </section>
  );
}

export default HeroHome;
