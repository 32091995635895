function PagesCopy({ copy1, copy2 }) {
  return (
    <section className="pages-copy">
      <p className="copy-question">{copy1}</p>
      <p className="copy-description">{copy2}</p>
    </section>
  );
}

export default PagesCopy;
    