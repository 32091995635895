import { useState } from "react";

const ebooks = [
  {
    id: 1,
    title: "Quem sou eu na fila do pão do conhecimento?",
    category: "Grátis",
    description:
      "Este e-book traz temas relacionados ao autoconhecimento, inteligência emocional, estilos de aprendizagem, mentalidade, metodologias de estudos e um questionário para que os estudantes possam olhar para si e se analisarem na jornada estudantil.",
    url: "https://chk.eduzz.com/2297264",
    imageUrl: "/ebooksCover/cover-book-5.webp",
  },
  {
    id: 2,
    title: "Como o reiki pode me ajudar?",
    category: "Grátis",
    description:
      "Conheça essa terapia energética e saiba como usá-la a seu favor. Neste e-book, apresento as bases do Reiki e sua correlação com os campos físico, emocional, mental e espiritual, além de um resumo sobre os sete chakras.",
    url: "https://chk.eduzz.com/2375126",
    imageUrl: "/ebooksCover/cover-book-4.webp",
  },
  {
    id: 3,
    title: "Manual básico de escrita formal acadêmica",
    category: "Pago",
    description:
      "Este é um e-book com temas básicos da escrita formal da língua portuguesa com foco na objetividade de escrita de textos acadêmico-científicos como TCC, dissertação ou tese.",
    url: "https://chk.eduzz.com/2296524",
    imageUrl: "/ebooksCover/cover-book-2.webp",
  },
  {
    id: 4,
    title: "Despedidas de mim",
    category: "Pago",
    description:
      "Esse é um e-book de poemas escritos entre 2008 até 2019. Despedidas de Mim faz parte de um processo de autoconhecimento e autovalorização de um trabalho subjetivo e artístico que, por diversas vezes, podem nos salvar da inanição e melancolia.",
    url: "https://chk.eduzz.com/2301555",
    imageUrl: "/ebooksCover/cover-book-1.webp",
  },
  {
    id: 5,
    title: "Educação Emocional para estudantes: primeiros passos",
    category: "Pago",
    description:
      "Neste e-book, você encontra o resumo dos pilares da inteligência emocional para a alfabetização emocional, além de seis tarefas práticas para implementar sua educação emocional de forma autônoma no dia a dia.",
    url: "https://chk.eduzz.com/2296563",
    imageUrl: "/ebooksCover/cover-book-7.webp",
  },
  {
    id: 6,
    title: "Benefícios do Reiki para estudantes",
    category: "Grátis",
    description:
      "A terapia oriental Reiki se popularizou no Ocidente por seus benefícios imediatos no bem-estar físico e emocional. Esse e-book pontua os principais benefícios do Reiki já comprovados em estudos científicos e traz possíveis aplicações para a vida acadêmica.",
    url: "https://chk.eduzz.com/2352033",
    imageUrl: "/ebooksCover/cover-book-6.webp",
  },
  {
    id: 7,
    title: "Guia prático para o bem-estar emocional",
    category: "Grátis",
    description:
      "Este e-book irá te ajudar a dar os primeiros passos no processo de autoconhecimento e gestão das suas emoções.",
    url: "https://chk.eduzz.com/2416237",
    imageUrl: "/ebooksCover/cover-book-3.webp",
  },
  {
    id: 8,
    title: "Mapa mental para escrita acadêmica",
    category: "Grátis",
    description:
      "Dicas básicas para organizar suas ideias no papel. Não deixe de conferir! Caso já tenha seu modo de escrever, veja se essas dicas podem complementar e te ajudar.",
    url: "https://chk.eduzz.com/2397983",
    imageUrl: "/ebooksCover/cover-book-8.webp",
  },
];

function EbooksList() {
  const categories = ["Todos", "Pago", "Grátis"];
  const [selectedCategory, setSelectedCategory] = useState("Todos");

  const filteredEbooks =
    selectedCategory === "Todos"
      ? ebooks
      : ebooks.filter((ebook) => ebook.category === selectedCategory);

  return (
    <section id="ebooks-section">
      <div className="ebooks-header">
        <h2>Use os filtros para localizar seu ebook</h2>
        <div className="filter-ebooks-container">
          {categories.map((category) => (
            <button
              className={`ebooks-category ${
                selectedCategory === category ? "selected" : ""
              }`}
              key={category}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="ebooks-container">
        {filteredEbooks.map((ebook) => (
          <div
            className="ebook-card"
            key={ebook.id}
          >
            <img
              src={ebook.imageUrl}
              alt={`capa do ebook ${ebook.title}`}
              loading="lazy"
            />
            <div className="ebook-description">
              <h3>{ebook.title}</h3>
              <p>{ebook.description}</p>
              <a
                href={ebook.url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link"
              >
                {ebook.category === "Pago" ? "Compre agora" : "Baixe agora"}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default EbooksList;
