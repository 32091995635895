import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import ServiceCards from "./_serviceCards";

function Cursos() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const storedServices = sessionStorage.getItem("cursos");

        if (storedServices) {
          setServices(JSON.parse(storedServices));
          console.log("pegou da session");
        } else {
          console.log("foi buscar outros");
          const servicesCollection = collection(db, "cursos");
          const servicesSnapshot = await getDocs(servicesCollection);
          const servicesList = servicesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          sessionStorage.setItem("cursos", JSON.stringify(servicesList));

          setServices(servicesList);
        }
      } catch (error) {
        console.error("Erro ao buscar os cursos: ", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <section id="courses">
      <div className="courses-container">
        {services.map((service, index) => (
          <ServiceCards
            key={index}
            title={service.title}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </section>
  );
}

export default Cursos;
