import { useState, useEffect } from "react";

function PopUpMessage() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const popupShown = sessionStorage.getItem("popupShown");
    if (!popupShown) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem("popupShown", "true");
  };

  if (!isVisible) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <h4>ATENÇÃO!</h4>
        <p>
          Tanto a venda quanto a compra de trabalhos acadêmicos prontos é
          considerado crime de falsidade ideológica, além de ser totalmente
          antiético. Esta empresa não compactua com tais atos.
        </p>
        <button onClick={handleClose}>Estou ciente</button>
      </div>
    </div>
  );
}

export default PopUpMessage;
