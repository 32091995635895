import { useNavigate } from "react-router-dom";

function AdminHeader() {
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear("user");
    navigate("/");
  };

  return (
    <section id="admin-header">
      <div className="admin-header">
        <h1 className="admin-title">Oi Carlinha!</h1>
        <button
          onClick={logout}
          className="logout-btn"
        >
          Logout
        </button>
      </div>
      <div className="admin-msg">
        <p>Aqui nessa página tu pode alterar algumas coisas e ver as mensagens que vieram pelo formulário de contato.</p>
      </div>
    </section>
  );
}

export default AdminHeader;
