import { useState, useEffect } from "react";
import HeroHome from "../../components/_heroHome";
import AboutHome from "../../components/_aboutHome";
// import Services from "../../components/_services";
import FreeContents from "../../components/_freeContents";

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <HeroHome />
      <AboutHome />
      {/* <Services /> */}
      <FreeContents />
    </div>
  );
}

export default Home;
