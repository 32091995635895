import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo-carlinha.png";
import ModalForm from "../components/_modalForm";

function Navbar() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const handleLinkClick = () => {
    setHamburgerOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveItem(path === "/" ? "" : path.substring(1));
  }, [location]);

  return (
    <nav>
      <div
        className="hamburger"
        onClick={toggleHamburger}
      >
        <div className="burger burger1" />
        <div className="burger burger2" />
        <div className="burger burger3" />
      </div>
      <div className={`navbar-container ${hamburgerOpen ? "open" : ""}`}>
        <div className="logo-container">
          <Link
            to="/"
            className={`logo ${activeItem === "" ? "active" : ""}`}
            onClick={handleLinkClick}
          >
            <img
              src={logo}
              alt="logo-carla mello"
            />
            Carla Mello
          </Link>
          <p></p>
        </div>
        <div className="links-container">
          <Link
            to="/"
            className={activeItem === "" ? "active" : ""}
            onClick={handleLinkClick}
          >
            Início
          </Link>
          <Link
            to="/mentorias-e-consultorias"
            className={
              activeItem === "mentorias-e-consultorias" ? "active" : ""
            }
            onClick={handleLinkClick}
          >
            Mentorias e Consultorias
          </Link>
          <Link
            to="/cursos"
            className={activeItem === "cursos" ? "active" : ""}
            onClick={handleLinkClick}
          >
            Cursos
          </Link>
          <Link
            to="/ebooks"
            className={activeItem === "ebooks" ? "active" : ""}
            onClick={handleLinkClick}
          >
            Ebooks
          </Link>
          <Link
            to="/sobre-mim"
            className={activeItem === "sobre-mim" ? "active" : ""}
            onClick={handleLinkClick}
          >
            Sobre mim
          </Link>
          <a
            href="https://carlaholistica.wordpress.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={activeItem === "blog" ? "active" : ""}
            onClick={handleLinkClick}
          >
            Blog
          </a>
          <Link
            to="#"
            onClick={openModal}
            className="contato-button"
          >
            Contato
          </Link>
          <ModalForm isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
