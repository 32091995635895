import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

import logo from "../assets/logo-carlinha.png";

function Footer() {
  const today = new Date();

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-cols">
          <Link
            to="/"
            className="logo"
          >
            <img
              src={logo}
              alt="logo-carla mello"
            />
            Carla Mello
          </Link>
          <p>
            Estudar é para a vida toda,
            <br /> para todas as vidas.
          </p>
        </div>
        <div className="footer-cols">
          <h5>Mapa do site</h5>
          <Link
            to="/"
            className="footer-link"
          >
            Início
          </Link>
          <Link
            to="/mentorias-e-consultorias"
            className="footer-link"
          >
            Mentorias e Consultorias
          </Link>
          <Link
            to="/cursos"
            className="footer-link"
          >
            Cursos
          </Link>
          <Link
            to="/ebooks"
            className="footer-link"
          >
            Ebooks
          </Link>
          <Link
            to="/sobre-mim"
            className="footer-link"
          >
            Sobre mim
          </Link>
          {/* <Link
            to="/admin"
            className="footer-link"
          >
            Área restrita
          </Link> */}
        </div>
        <div className="footer-cols">
          <h5>Contato e redes sociais</h5>
          <a
            href="tel:+5548991623337"
            className="footer-link"
          >
            (48) 99162-3337
          </a>
          <a
            href="mailto:carlinhamello84@gmail.com"
            className="footer-link"
          >
            carlinhamello84@gmail.com
          </a>
          <a
            href="https://www.instagram.com/carlamelloprof/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <FontAwesomeIcon icon={faInstagram} /> Instagram
          </a>
          <a
            href="https://www.youtube.com/user/MelloCarla"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <FontAwesomeIcon icon={faYoutube} /> Youtube
          </a>
        </div>
      </div>
      <hr />
      <div>
        <p className="signature">
          © {today.getFullYear()} - Made with{" "}
          <FontAwesomeIcon
            icon={faHeart}
            className="icon-heart"
          />{" "}
          by{" "}
          <a
            href="https://augusto-mattos.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="link-signature"
          >
            Augusto Mattos
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
