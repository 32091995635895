function ServiceCards({ title, description, link }) {
  return (
    <div className="service-card">
      <h3>{title}</h3>
      <p>{description}</p>
      {link && (  
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inscreva-se agora
        </a>
      )}
    </div>
  );
}

export default ServiceCards;
