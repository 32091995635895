import { useEffect, useState } from "react";
import HeroPages from "../../components/_heroPages";
import CopyAboutMe from "../../components/_copyAboutMe";
import Mission from "../../components/_mission";
import CTA from "../../components/_cta";
import FreeContents from "../../components/_freeContents";
import ModalForm from "../../components/_modalForm";

function About() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assunto, setAssunto] = useState('');

  const openModalWithSubject = (subject) => {
    setAssunto(subject);
    setIsModalOpen(true);
  };

  const handleOpenModal = () => {
    openModalWithSubject('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  return(
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <HeroPages
        title="Sobre mim"
        description="Conheça um pouco mais sobre mim e veja se ressoa com você. É aqui que começa a nossa Relação. Obrigada por me permitir estar contigo!"
      />
      <CopyAboutMe />
      <Mission />
      <CTA
        text="Quer saber mais sobre os diferentes tipos de "
        spanText="serviços oferecidos?"
        buttonText="Envie uma mensagem"
        onButtonClick={handleOpenModal}
      />
      <FreeContents />
      <ModalForm 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        assuntoInicial={assunto}
      />
    </div>
    )
  }

  export default About;