function CTA({ text, spanText, buttonText, onButtonClick }) {
  return (
    <div id="cta">
      <p>
        {text} <span>{spanText}</span>
      </p>
      <button onClick={onButtonClick}>{buttonText}</button>
    </div>
  );
}

export default CTA;
