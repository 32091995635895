import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/routes/_protectedRoute";

import Layout from "./layout/_layout";

import Home from "./pages/Home";
import About from "./pages/About";
import Courses from "./pages/Courses";
import Coaching from "./pages/Coaching";
import Ebooks from "./pages/Ebooks";
import LoginPage from "./pages/Login";
import Admin from "./pages/Admin";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/mentorias-e-consultorias"
          element={
            <Layout>
              <Coaching />
            </Layout>
          }
        />
        <Route
          path="/cursos"
          element={
            <Layout>
              <Courses />
            </Layout>
          }
        />
        <Route
          path="/ebooks"
          element={
            <Layout>
              <Ebooks />
            </Layout>
          }
        />
        <Route
          path="/sobre-mim"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
