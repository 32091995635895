import { useState, useEffect } from "react";
import Services from "./_services";
import firulaAzul from "../assets/firula-azul.svg";

function AboutHome() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="about-home-container">
      {isWideScreen ? (
        <>
          <div className="firula-container">
            <img src={firulaAzul} alt="Firula Azul" />
          </div>
          <div className="about-home-copy">
            <div className="copy-col-1">
              <p>
                Aqui você vai encontrar serviços educacionais com um olhar holístico e terapêutico.
              </p>
            </div>
            <div className="copy-col-2">
              <p>
                Você deve estar se perguntando o que educação tem a ver com terapias holísticas, certo?! Sim, num primeiro momento, uma não possui qualquer relação com a outra, porém, como trabalho com as duas áreas resolvi colocar tudo junto e misturado.
              </p>
              <p>
                Além disso, em todos os serviços que ofereço eu trago um olhar holístico e integrado, ou seja, global, do todo, pois isso implica numa visão de mundo onde nada está separado, ou seja, as partes afetam o todo e vice-versa. Assim, dentro das mentorias e consultorias acadêmicas há influências de técnicas terapêuticas, se for o caso. O fato é que a integração nos permite ter autonomia e liberdade para trabalhar de forma mais ampla. Por isso mesmo optei por fazer atendimentos personalizados online.
              </p>
              <p>
                Resumidamente, aqui, e em qualquer serviço, estamos sempre falando de Relação entre seres humanos e suas complexidades, e não apenas de técnicas e protocolos a serem seguidos. Desse modo, todos os serviços que ofereço conectam-se com emoções e sentimentos tanto meus quanto da/o cliente em interação para melhor servir ao propósito naquele contexto.
              </p>
            </div>
          </div>
          <Services />
        </>
      ) : (
        <>
          <div className="firula-container">
            <img src={firulaAzul} alt="Firula Azul" />
          </div>
          <div className="about-home-copy">
            <div className="copy-col-1">
              <p>
                Aqui você vai encontrar serviços educacionais com um olhar holístico e terapêutico.
              </p>
            </div>
            <Services />
            <div className="copy-col-2">
              <p>
                Você deve estar se perguntando o que educação tem a ver com terapias holísticas, certo?! Sim, num primeiro momento, uma não possui qualquer relação com a outra, porém, como trabalho com as duas áreas resolvi colocar tudo junto e misturado.
              </p>
              <p>
                Além disso, em todos os serviços que ofereço eu trago um olhar holístico e integrado, ou seja, global, do todo, pois isso implica numa visão de mundo onde nada está separado, ou seja, as partes afetam o todo e vice-versa. Assim, dentro das mentorias e consultorias acadêmicas há influências de técnicas terapêuticas, se for o caso. O fato é que a integração nos permite ter autonomia e liberdade para trabalhar de forma mais ampla. Por isso mesmo optei por fazer atendimentos personalizados online.
              </p>
              <p>
                Resumidamente, aqui, e em qualquer serviço, estamos sempre falando de Relação entre seres humanos e suas complexidades, e não apenas de técnicas e protocolos a serem seguidos. Desse modo, todos os serviços que ofereço conectam-se com emoções e sentimentos tanto meus quanto da/o cliente em interação para melhor servir ao propósito naquele contexto.
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default AboutHome;

