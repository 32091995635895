import { Link } from "react-router-dom";
import fotoAboutMe from "../assets/fotoAboutMe.webp";

function CopyAboutMe() {
  return (
    <section id="about-me-container">
      <div className="image-container">
        <img
          src={fotoAboutMe}
          alt="fotografia de Carla Mello"
          className="photo-about-me"
          loading="lazy"
        />
      </div>
      <div className="about-me-copy">
        <p>Quem sou eu?</p>
        <p>
          Sou uma pessoa apaixonada pelo que faz e que ama interagir com as
          pessoas! Com 12 anos de experiência na vida acadêmica, tornei-me
          professora, escritora e revisora de textos.
        </p>
        <p>
          Após finalizar meu doutorado em Literatura, também fiz cursos
          profissionalizantes na área de terapias holísticas (Reiki Usui,
          Psicoterapia Holística, Karuna, Ho'oponopono e Reiki xamânico), pelas
          quais me encantei. Ainda, iniciei na prática de Comunicação Não
          Violenta e Educação Emocional.
        </p>
        <p>
          A partir disso, juntei meus conhecimentos para oferecer serviços às
          pessoas para além da parte técnica/estrutural de escrita, e também ser
          mediadora na sua busca pelo desenvolvimento da Inteligência Emocional
          e da saúde plena.
        </p>
        <p>
          Saiba sobre a minha história com o reiki{" "}
          <Link
            to="https://carlaholistica.wordpress.com/2022/01/01/minha-historia-com-o-reiki/"
            className="link-reiki"
            target="_blank"
            rel="noopener noreferrer"
          >
            clicando aqui
          </Link>
          .
        </p>
        <p>
          Link para acessar meu{" "}
          <Link
            to="http://lattes.cnpq.br/7381253082104512"
            className="link-lattes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Currículo Lattes
          </Link>
          .
        </p>
      </div>
    </section>
  );
}

export default CopyAboutMe;
