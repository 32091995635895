function HowItWorks() {
  return (
    <section id="how-it-works">
      <h4>Como funcionam as mentorias e consultorias?</h4>
      <p>
        Os atendimentos são online. Você agenda um horário e preenche um
        formulário que será enviado por e-mail.
        <br /> Suas respostas irão orientar nossa conversa.
      </p>
    </section>
  );
}

export default HowItWorks;
