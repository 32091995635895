import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { db, collection, addDoc } from "../services/firebase"; 
import emailjs from '@emailjs/browser';

function ModalForm({ isOpen, onClose, assuntoInicial }) {
  const [assunto, setAssunto] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(""); 
  const form = useRef();

  useEffect(() => {
    setAssunto(assuntoInicial || "");
  }, [assuntoInicial]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const mensagem = formData.get("mensagem");
    const regex = /<\/?[a-z][\s\S]*>|https?:\/\/[\S]+/i;

    if (regex.test(mensagem)) {
      alert("A mensagem não pode conter links ou código.");
      return;
    }

    try {
      await addDoc(collection(db, "formSubmissions"), {
        nome: formData.get("nome"),
        email: formData.get("email"),
        assunto: formData.get("assunto"),
        mensagem: formData.get("mensagem"),
        timestamp: new Date(),
      });

      await emailjs
      .sendForm('service_carlamello_msg', 'template_9neikgr', form.current, {
        publicKey: 'ObLwEicSkS9SVdUIf',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      setSubmissionStatus("Formulário enviado com sucesso!");
      setTimeout(() => {
        setSubmissionStatus("");
        onClose();
      }, 4500); 
    } catch (error) {
      setSubmissionStatus("Ocorreu um erro ao salvar o formulário.");
      console.error("Erro ao adicionar documento: ", error);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Contato</h2>
        {submissionStatus ? (
          <div className="confirmation-message">
            <p>{submissionStatus}</p>
          </div>
        ) : (
          <form ref={form} onSubmit={handleSubmit}>
            <div>
              <label>Como você se chama?</label>
              <input type="text" name="nome" required />
            </div>
            <div>
              <label>Qual seu e-mail?</label>
              <input type="email" name="email" required />
            </div>
            <div>
              <label>Sobre qual assunto você quer falar?</label>
              <select
                name="assunto"
                value={assunto}
                onChange={(e) => setAssunto(e.target.value)}
                required
              >
                <option value="" disabled>
                  Selecione um assunto
                </option>
                <option value="Mentorias e Consultorias">
                  Mentorias e Consultorias
                </option>
                <option value="Cursos">Cursos</option>
                <option value="Outros">Outros temas</option>
              </select>
            </div>
            <div>
              <label>Escreva sua mensagem</label>
              <textarea name="mensagem" required></textarea>
            </div>
            <button type="submit">Enviar</button>
          </form>
        )}
        <div className="contact-options">
          <p>Prefere falar diretamente?</p>
          <div className="contact-icons">
            <a
              href="https://wa.me/5548991623337"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> (48) 99162-3337
            </a>
            <a href="mailto:email@example.com">
              <FontAwesomeIcon icon={faEnvelope} /> carlinhamello84@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
