import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import shape from "../assets/shape.png";

function ConsultingServices() {
  const [consultingServices, setConsultingServices] = useState([]);

  useEffect(() => {
    const fetchConsultingServices = async () => {
      try {
        const storedConsultingServices = sessionStorage.getItem(
          "consultorias-academicas"
        );

        if (storedConsultingServices) {
          setConsultingServices(JSON.parse(storedConsultingServices));
          console.log("Pegou da session");
        } else {
          console.log("Foi buscar outros");
          const consultingServicesCollection = collection(
            db,
            "consultorias-academicas"
          );
          const servicesSnapshot = await getDocs(consultingServicesCollection);
          const servicesList = servicesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          sessionStorage.setItem(
            "consultorias-academicas",
            JSON.stringify(servicesList)
          );

          setConsultingServices(servicesList);
        }
      } catch (error) {
        console.error("Erro ao buscar os serviços: ", error);
      }
    };

    fetchConsultingServices();
  }, []);

  return (
    <section id="consulting-services">
      <div className="services-title-container">
        <h2>Consultorias acadêmicas</h2>
      </div>
      <div className="consulting-cards-container">
        {consultingServices.map((service) => (
          <div
            key={service.id}
            className="consulting-card"
          >
            <img
              src={shape}
              alt="Ícone do serviço"
              className="service-image"
            />
            <h3 className="consulting-title">{service.title}</h3>
            <p className="consulting-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ConsultingServices;
