import logoYoutube from "../assets/logo-YouTube.webp";
import logoSpotify from "../assets/Spotify_logo_without_text.svg";
import logoJornada from "../assets/jornada.webp";

function FreeContents() {
  return (
    <section className="free-content-section">
      <div className="free-content-title-container">
        <p className="free-content-title">Conteúdos gratuitos</p>
      </div>
      <div className="free-content-container">
        <a
          href="https://www.youtube.com/user/MelloCarla"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logoYoutube}
            alt="link para o canal no Youtube"
            className="free-content-logo"
            loading="lazy"
          />
          <p>
            Meu canal <br />
            no YouTube
          </p>
        </a>
        <a
          href="https://open.spotify.com/show/7B7IFdok3YweXHsF0uY73J?si=52fcdbe5363b497d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logoSpotify}
            alt="link para podcast pão com prosa"
            className="free-content-logo"
            loading="lazy"
          />
          <p>
            Podcast
            <br /> "Pão com prosa"
          </p>
        </a>
        <a
          href="https://www.youtube.com/user/MelloCarla"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logoJornada}
            alt="link para jornada da gratidão"
            className="free-content-logo"
            loading="lazy"
          />
          <p>
            Jornada de <br />
            Gratidão e Reiki
          </p>
        </a>
      </div>
    </section>
  );
}

export default FreeContents;
