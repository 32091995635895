import { useState } from "react";
import AdminHeader from "../../components/_adminHeader";
import AdminMentoringServices from "../../components/_adminMentoringServices";
import AdminMessages from "../../components/_adminMessages";

function Admin() {

  const [selectedComponent, setSelectedComponent] = useState('messages');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'messages':
        return <AdminMessages />;
      case 'services':
        return <AdminMentoringServices />;
      default:
        return <AdminMessages />;
    }
  };

  return (
    <>
      <AdminHeader />
      {/* Botões para selecionar o componente */}
      <div className="admin-btn-container">
        <button 
          className={selectedComponent === 'messages' ? 'selected' : ''} 
          onClick={() => setSelectedComponent('messages')}
        >
          Mensagens recebidas
        </button>
        <button 
          className={selectedComponent === 'services' ? 'selected' : ''} 
          onClick={() => setSelectedComponent('services')}
        >
          Serviços de mentoria
        </button>
      </div>
      {/* Renderiza o componente selecionado */}
      {renderComponent()}
    </>
  );
}

export default Admin;

// TOUTE LA PARTIE ADMIN EST ENCORE A DEVELOPPER
