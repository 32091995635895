import Navbar from "./_navbar";
import Footer from "./_footer";
import PropTypes from "prop-types";
import Popup from '../components/_popUpZap'; 

const Layout = ({ children }) => {

  return (
    <>
      <header>{<Navbar />}</header>
      <main>{children}</main>
      <Footer />
      <Popup />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
