import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import ServiceCards from "./_serviceCards";

function MentoringServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const storedServices = sessionStorage.getItem("mentoring-services");

        if (storedServices) {
          setServices(JSON.parse(storedServices));
          console.log("pegou da session");
        } else {
          console.log("foi buscar outros");
          const servicesCollection = collection(db, "mentoring-services");
          const servicesSnapshot = await getDocs(servicesCollection);
          const servicesList = servicesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          sessionStorage.setItem(
            "mentoring-services",
            JSON.stringify(servicesList)
          );

          setServices(servicesList);
        }
      } catch (error) {
        console.error("Erro ao buscar os serviços: ", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <section id="mentoring-services">
      <div className="mentoring-title-container">
        <h2>Mentorias</h2>
      </div>
      <div className="services-container">
        {services.map((service, index) => (
          <ServiceCards
            key={index}
            title={service.title}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </section>
  );
}

export default MentoringServices;
