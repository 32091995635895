import { Link } from "react-router-dom";

import mentoriaIcon from "../assets/mentoria-icon.png";
import ebookIcon from "../assets/escrita-icon.png";
import coursesIcon from "../assets/cursos-icon.png";

function Services() {
  return (
    <div className="services-section">
      <div className="services-container">
        <div className="service-copy">
          <img
            src={mentoriaIcon}
            alt="consultorias e mentorias"
            className="service-icon"
          />
          <h3 className="service-title">Mentorias e Consultorias</h3>
          <p>
            Atendimentos em grupo ou individuais com foco nos estudos ou em
            educação emocional aplicada.
          </p>
          <Link
            to="/mentorias-e-consultorias"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
        <div className="service-copy">
          <img
            src={coursesIcon}
            alt="cursos"
            className="service-icon"
          />
          <h3 className="service-title">Cursos</h3>
          <p>Cursos nas áreas acadêmica e de educação emocional.</p>
          <Link
            to="/cursos"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
        <div className="service-copy">
          <img
            src={ebookIcon}
            alt="ebooks"
            className="service-icon"
          />
          <h3 className="service-title">Ebooks</h3>
          <p>Materiais úteis para estudantes e/ou sobre terapias holísticas.</p>
          <Link
            to="/ebooks"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
