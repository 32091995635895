import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUid(user.uid);
      sessionStorage.setItem("user", user.uid);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Usuário não encontrado.");
      } else if (error.code === "auth/wrong-password") {
        setError("Senha incorreta.");
      } else if (error.code === "auth/invalid-email") {
        setError("E-mail inválido.");
      } else {
        setError("Credenciais inválidas!");
      }
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      navigate("/admin");
    }
  }, [navigate]);

  useEffect(() => {
    if (uid) {
      window.location.href = "/admin";
    }
  }, [uid, navigate]);

  return (
    <>
      <div className="login-form-container">
        <h1>Login</h1>
        <form action="">
          <div>
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </form>
        {error && <p className="error-msg">{error}</p>}
        <div className="btn-container">
          <button
            onClick={() => navigate("/")}
            className="back-btn"
          >
            Voltar
          </button>
          <button
            onClick={handleLogin}
            className="login-btn"
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
