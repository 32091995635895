import { useEffect, useState } from "react";
import HeroPages from "../../components/_heroPages";
import PagesCopy from "../../components/_pagesCopy";
import EbooksList from "../../components/_ebooksList";
import CTA from "../../components/_cta";
import FreeContents from "../../components/_freeContents";
import ModalForm from "../../components/_modalForm";

function Ebooks() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assunto, setAssunto] = useState("");

  const openModalWithSubject = (subject) => {
    setAssunto(subject);
    setIsModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenModal = () => {
    openModalWithSubject("");
  };

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <HeroPages
        title="Ebooks"
        description="Nesta página você vai encontrar conteúdos úteis para auxiliar na sua jornada de conhecimento linguístico, emocional e/ou terapêutico."
      />
      <PagesCopy
        copy1="Leia  mais e amplie sua consciência!"
        copy2="Conheça meus e-books das áreas de linguagens, poesia, terapias e educação emocional."
      />
      <EbooksList />
      <CTA
        text="Quer saber mais sobre os diferentes tipos de "
        spanText="serviços oferecidos?"
        buttonText="Envie uma mensagem"
        onButtonClick={handleOpenModal}
      />
      <FreeContents />
      <ModalForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        assuntoInicial={assunto}
      />
    </div>
  );
}

export default Ebooks;
