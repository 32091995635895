import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

function Mission() {
  const [expanded, setExpanded] = useState(null); // Estado para o painel atualmente expandido

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    // Atualiza o estado para expandir o painel clicado e retrair o painel anteriormente expandido
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <section id="mission">
      <div>
        <Accordion
          expanded={expanded === "mission"} // Verifica se o painel "mission" está expandido
          onChange={handleAccordionChange("mission")}
          id="accordion-mission"
        >
          <AccordionSummary
            expandIcon={
              expanded === "mission" ? (
                <span className="accordion-btn">
                  <FontAwesomeIcon icon={faMinus} />
                </span>
              ) : (
                <span className="accordion-btn">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              )
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="accordion-title">Missão</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-item-content">
            <Typography className="description">
              Oferecer um atendimento holístico a todas as pessoas que queiram
              integrar suas três inteligências (intelectual, emocional e
              espiritual) para aplicar em todas as áreas da vida e criar
              autonomia de aprendizagem e autocuidado, utilizando o método
              Estudar com Emoção.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "values"}
          onChange={handleAccordionChange("values")}
          id="accordion-values"
        >
          <AccordionSummary
            expandIcon={
              expanded === "values" ? (
                <span className="accordion-btn">
                  <FontAwesomeIcon icon={faMinus} />
                </span>
              ) : (
                <span className="accordion-btn">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              )
            }
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="accordion-title">Valores</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-item-content">
            <Typography className="description">
              Ética, responsabilidade, empatia, comunicação não violenta,
              inteligência emocional, inteligência espiritual, cooperação.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </section>
  );
}

export default Mission;
