import { useEffect, useState } from "react";
import HeroPages from "../../components/_heroPages";
import PagesCopy from "../../components/_pagesCopy";
import Cursos from "../../components/_courses";
import CTA from "../../components/_cta";
import FreeContents from "../../components/_freeContents";
import ModalForm from "../../components/_modalForm";

function Coaching() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assunto, setAssunto] = useState('');

  const openModalWithSubject = (subject) => {
    setAssunto(subject);
    setIsModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenModal = () => {
    openModalWithSubject('Cursos');
  };

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <HeroPages
        title="Cursos"
        description="Aqui você vai encontrar cursos autoguiados para se aprimorar na escrita formal da língua portuguesa ou para trabalhar sua educação emocional."
      />
      <PagesCopy
        copy1="Você gosta de estudar?"
        copy2="Quando digo que estudar é para a vida toda, pode-se perceber através da necessidade constante de aprimoramento que a pessoa busca. Não é algo simples, mas completamente possível de se fazer: tornar-se autoconsciente do seu processo de aprendizado."
      />
      <Cursos />
      <CTA
        text="Quer saber mais sobre os "
        spanText="cursos?"
        buttonText="Envie uma mensagem"
        onButtonClick={handleOpenModal}
      />
      <FreeContents />
      <ModalForm 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        assuntoInicial={assunto}
      />
    </div>
  );
}

export default Coaching;
