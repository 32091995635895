import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const footerPosition = document.body.scrollHeight - 600;

      // Exibe o pop-up se o scroll for maior que metade da página
      if (scrollPosition >= footerPosition) {
        if (!isVisible) {
          setIsVisible(true);
          setIsAnimated(true); 
          setTimeout(() => setIsAnimated(false), 750); 
        }
      } else {
        setIsVisible(false);
        setIsExpanded(false); 
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (!isVisible) return null; // Não renderiza se não for visível

  return (
    <div className={`popup-container ${isAnimated ? 'popup-animated' : ''}`}>
      {!isExpanded && (
        <div
          className="popup-call-to-action"
          onClick={handleToggle}
        >
          Fique por dentro das novidades!
        </div>
      )}
      {isExpanded && (
        <div className="popup-expanded-content">
          <div className="popup-header">
            <button
              onClick={handleToggle}
              className="popup-icon-button"
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
          </div>
          <h3>Quer ficar mais perto das novidades?</h3>
          <p>
            Entre agora mesmo no meu grupo de aproximação pelo WhatsApp e
            participe de encontros gratuitos!
          </p>
          <a
            href="https://chat.whatsapp.com/CAjcvv1EIU97mpLGq1SSFy"
            target="_blank"
            rel="noopener noreferrer"
            className="popup-link"
          >
            Entrar no grupo
          </a>
        </div>
      )}
    </div>
  );
};

export default Popup;
