import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../services/firebase";

function AdminMessages() {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "formSubmissions"));
        const submissionsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordena as mensagens da mais recente para a mais antiga
        submissionsArray.sort(
          (a, b) => b.timestamp.toDate() - a.timestamp.toDate()
        );
        setSubmissions(submissionsArray);
      } catch (error) {
        console.error("Erro ao buscar mensagens: ", error);
      }
    };

    fetchData();
  }, []);

  const handleResponse = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus; // Inverte o status
      const messageDoc = doc(db, "formSubmissions", id);
      await updateDoc(messageDoc, { respondido: newStatus });
      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.id === id
            ? { ...submission, respondido: newStatus }
            : submission
        )
      );
    } catch (error) {
      console.error("Erro ao atualizar o status da mensagem: ", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Tem certeza que deseja deletar esta mensagem?"
    );
    if (confirmDelete) {
      try {
        const messageDoc = doc(db, "formSubmissions", id);
        await deleteDoc(messageDoc);
        setSubmissions((prevSubmissions) =>
          prevSubmissions.filter((submission) => submission.id !== id)
        );
      } catch (error) {
        console.error("Erro ao deletar a mensagem: ", error);
      }
    }
  };

  return (
    <section id="admin-messages-container">
      <h2>Mensagens Recebidas</h2>
      <div className="boite-mail">
        {submissions.map((submission) => (
          <div             className={`mail-container ${submission.respondido ? 'respondido' : 'nao-respondido'}`}
>
            <div className="sender">
              {submission.nome} - {submission.email} - {new Date(submission.timestamp.toDate()).toLocaleString()}
            </div>
            <div className="object">
              <div>
                <h3>{submission.assunto}</h3>
              </div>
              <div>
                <button
                  className={submission.respondido ? 'respondido' : 'nao-respondido'}
                  onClick={() => handleResponse(submission.id, submission.respondido)}
                >
                  {submission.respondido ? "Marcar como Não Respondido" : "Marcar como Respondido"}
                </button>
                <button
                  className="delete"
                  onClick={() => handleDelete(submission.id)}
                >
                  Deletar
                </button>
              </div>
            </div>
            <div className="mensagem">
              {submission.mensagem}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default AdminMessages;
