import firulaVerde from "../assets/Vector.svg";

function HeroPages({ title, description }) {
  return (
    <div className="hero-pages">
      <div className="hero-content-container">
        <img
          src={firulaVerde}
          alt="pictograma"
        />
        <h1 className="hero-pages-title">{title}</h1>
        <p className="hero-pages-description">{description}</p>
      </div>
    </div>
  );
}

export default HeroPages;
