import { useState, useEffect } from "react";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../services/firebase";

function AdminMentoringServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const storedServices = sessionStorage.getItem("mentoring-services");

        if (storedServices) {
          setServices(JSON.parse(storedServices));
          console.log("pegou da session");
        } else {
          console.log("foi buscar outros");
          const servicesCollection = collection(db, "mentoring-services");
          const servicesSnapshot = await getDocs(servicesCollection);
          const servicesList = servicesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          sessionStorage.setItem(
            "mentoring-services",
            JSON.stringify(servicesList)
          );

          setServices(servicesList);
        }
      } catch (error) {
        console.error("Erro ao buscar os serviços: ", error);
      }
    };

    fetchServices();
  }, []);

  const handleAddService = async (newService) => {
    try {
      const serviceRef = await addDoc(
        collection(db, "mentoring-services"),
        newService
      );
      setServices([...services, { id: serviceRef.id, ...newService }]);
      console.log("Novo serviço adicionado.");
    } catch (error) {
      console.error("Erro ao adicionar o serviço: ", error);
    }
  };

  return (
    <section id="mentoring-services-adm">
      <div className="service-adm">
        {services.map((service, index) => (
          <div
            key={index}
            id={service.id}
          >
            <div>
              <h4>{service.title}</h4>
              <p>{service.description}</p>
            </div>
            <div className="service-btns-admin">
              <button>deletar</button>
              <button>editar</button>
            </div>
          </div>
        ))}
      </div>
      <div className="add-new">
        <form onSubmit={handleAddService}>
          <input
            type="text"
            name="title"
            placeholder="Título"
            required
          />
          <textarea
            name="description"
            placeholder="Descrição"
            required
          />
          <button type="submit">Adicionar Serviço</button>
        </form>
      </div>
    </section>
  );
}

export default AdminMentoringServices;
